.headerpage .navbar{
    background: 50% no-repeat #fff;
    width: 100%;
}
.headerpage .navbar.fixed_white{
background: #fff !important;
    position: fixed !important;
    top: 0px !important;
    -webkit-backface-visibility: hidden;
    box-shadow: 0 2px 8px 0 rgb(0 0 0 / 10%);
    width: 100%;
    z-index: 9;
    
}

.headerpage .navbar-collapse {
    justify-content: end;
}
    
    .blogpage .top_sec .first_row{
        text-align: center;
    }
    .blogpage .top_sec .first_row .head_title{
    color: #0B0C0D;
    font-weight: 700;
    }
    .blogpage .top_sec .first_row .head_title span{
        color: #0098fb;
        font-weight: 700;
        margin-left: 10px;
    }
    .blogpage .top_sec .first_row .head_desc{
        color: #3F4751;
        font-weight: 400;
        font-size: 16px;
        font-style: italic;
    }

    /* blogtopcard start */
    .blog_top_card{
        background: #FFFFFF;
        border: 1px solid #E2E5E9;
        border-radius: 6.4px;
        text-align: center;
        padding: 25px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 100%;
    }
    .blog_top_card .card_title{
        font-style: italic;
        font-weight: 700;
        font-size: 18px; 
        margin-bottom: 0px;
        color: #0098fb;
    }
    .top_sec .bw.sec_title{
        font-size: 30px;
        margin-top: 20px;
    }
    .blog_top_card:hover{
        border: 1px solid #0098fb;
    }
    .blog_top_card .card_desc{
        font-style: italic;
        font-weight: 400;
        font-size: 16px; 
        margin-bottom: 20px;
    }
    .blog_top_card .linkclass{
        color: #000;
        font-weight: 700;
        font-style: italic;
    }
    /* blogtopcardend*/
    .recent_blog.first{
        background: #fff;
    }

    /* blogcardstart */
    .blog_card .card_title{
        color: #1A90DD;
        letter-spacing: 0.5px;
    text-transform: uppercase;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    margin-top: 20px;
    }
    .blog_card .card_desc{
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 34px;

    color: #0B0C0D;
    text-align: left;
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    min-height: 70px;
    max-height: 70px;

    }

    .blog_card .blog_card_img{
        width: 100%;
        min-height: 220px;
        max-height: 220px;
        object-fit: cover;
    }

    /* blogcardend */

    /* blogcardsec  start*/
    .blog_category_list .category_heading{
        position: relative;
    
    }
    .blog_category_list .category_heading .category_title{
        font-style: italic;
    font-weight: 700;
    font-size: 24px;
    line-height: 42px;
    color: #0B0C0D;
    align-items: center;
    display: flex;

    }
    .blog_category_list .category_heading .category_order{

        color: #F5F7F9;
        font-size: 70px;
    line-height: 42px;
    font-weight: 700;
    margin-bottom: 0px;
    margin-right: -12px;

    }
    .linkbtn{
        background: #FFFFFF;
    border: 1px solid #DADCE0;
    border-radius: 4px;
    color: #1A90DD;
    padding: 10px;
    font-size: 12px;
    }
    .blog_category_list .btnsec{
        display:flex;
        align-items: center;
        justify-content: center;
        margin-top: 30px;
        margin-bottom: 30px;
    }
    /* blogcardsec end */


    /* recentblog start*/
    .recent_blog .sec_title{
        font-size: 22px;
        font-weight: 700;
        margin-bottom: 40px;
    }
    .recent_blog{
        background-color: #F5F7F9;
        padding-top: 50px;
        padding-bottom: 50px;
        margin-bottom: 40px;
    }

    /* recentblog end */

    /* adbanner start*/
    .ad_banner {
        background-color: #F8F9FA;
        padding: 20px;
        border-radius: 20px;
        text-align: center;
    }
    .ad_banner .input_sec {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 20px;

    }
    .ad_banner .ad_row{
        align-items: center;
    }
    .ad_banner .headtitle{
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 30px;
    letter-spacing: -0.5px;

    color: #202124;

    }
    .ad_banner .headtitle span{
    color: #1A90DD;
    }
    .ad_banner .sec_desc{
        font-size: 12px;
        line-height: 20px;
        padding-top: 20px;
    }
    .sub_btn{
        background: #1A90DD;
    border-radius: 4px;
    color: #fff;
    }
    .sub_btn:hover{
        background: transparent;
    border-radius: 4px;
    color: #1A90DD;
    border: 1px solid #1A90DD;
    }
    .ad_banner_sec{
        padding-bottom: 70px;
    }

    /* adbanner end*/

    /* trendsec start */
    .trend_sec .trend_img{
        width: 100%;
        height: 370px;
        object-fit: cover;
    }
    .trend_sec .trendleft_card{
        background: #FFFFFF;
    box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.11);
    border-radius: 16px 0px 16px 16px;
    padding: 25px;
    width: 90%;
        margin-left: auto;
        margin-right: 12px;
        margin-top: -40px;
    }
    .trendleft_card .card_mini_title{
        color: #1A90DD;
        font-size: 14px;
    }
    .trendleft_card .card_title{
        color: #202124;
        font-size: 18px;
        text-decoration-line: underline;
        font-weight: 700;
    }
    .trendleft_card .card_desc{

    font-size: 14px;
    line-height: 22px;
    color: #5F6368;
    }
    .trend_sec .leftsec .img_sec{
    position: relative;
    z-index: -1;
    }
    .trend_sec .leftsec .img_sec .img_label{
        position: absolute;
        top: 25px;
        }
        .trend_sec .leftsec .img_sec .img_label p{
            background: #E6F5FF;
            color: #000;
            border-top-right-radius: 20px;
            border-bottom-right-radius: 20px;
            padding: 10px;
            font-weight: 600;
            
            }

            .trend_right_card .trend_img{
                width: 100%;
                height: 200px;
                object-fit: cover;
            }
            .trend_right_card .card_mini_title{
            font-size: 12px;
            color: #1A90DD;
            margin-top: 20px;
            }
            .trend_right_card .card_title{
                font-size: 14px;
                color: #202124;
            }
        .trend_sec .owl-carousel{position:unset;
        }    
        .trend_sec{
            position: relative;
            padding-bottom: 80px;
        
        }
        .trend_sec .owl-theme .owl-dots {
            text-align: center;
            -webkit-tap-highlight-color: transparent;
            position: absolute;
            left: 40%;
            padding-top: 30px;
        
        }
        .trend_sec .owl-theme .owl-dots .owl-dot span {
            width: 45px;
            background-color: #CDEBFF;
            height: 5px;
        }
        .trend_sec .owl-theme .owl-dots .owl-dot.active span {
            width: 45px;
            background-color: #1A90DD;
        }
    /*  trendsec end */


    /* blogdetailpage start */
    .blogdetail_page .topsec{
        margin-top: 80px;
    }
    .blogdetail_page .topsec .blog_title{
        font-size: 35px;
        font-weight: 600;
        text-align: center;
        color: #0B0C0D;
    }

    .blogdetail_page .topsec .blog_cat{
        text-align: center;
        font-size: 18px;
    }

    .blogdetail_page .categorysec .blogcat_box{
    background: #FFFFFF;
    border: 1px solid #E2E5E9;
    border-radius: 6.4px;
    padding: 10px;
    position: relative;
    height: 100%;
    align-items: center;
    display: flex;
    gap: 20px;
    }
    .blogdetail_page .categorysec .blogcat_box:hover{
 border: 1px solid  #0098fb;
    }
   
    .blogdetail_page .categorysec .blogcat_box .catg_desc{
    font-style: normal;
    font-weight: 700;
    font-size: 11.8px;
    line-height: 19px;
    align-items: center;
    letter-spacing: 1.28px;
    text-transform: uppercase;

    color: #757E8A;
    margin-bottom: 0px;

    }
    .blogdetail_page .categorysec .blogcat_box .catg_title{
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 34px;
    align-items: center;
    color: #0098fb;
    margin-bottom: 5px;

    }
    .blogdetail_page .categorysec .blogcat_box i{
        position: absolute;
        right: 15px;
        top: 43%;
        font-size: 25px;
        font-weight: 600;
    }
    .blogdetail_page .categorysec .sec_title{
font-style: normal;
font-weight: 700;
font-size: 32px;
line-height: 50px;
align-items: center;
color: #0B0C0D;
margin-top: 30px;
margin-bottom: 30px;

    }

.blogdetailcard .blog_card .card_desc{
    margin-top: 15px;
}
.blog_collection .readmoreblog .read_title{
font-style: italic;
font-weight: 700;
font-size: 32px;
line-height: 42px;
align-items: center;
text-align: center;

color: #0B0C0D;
}
.blog_collection .readmoreblog .read_title span{
font-style: italic;
font-weight: 700;
font-size: 31px;
line-height: 42px;
align-items: center;
text-align: center;

color: #0098fb;

}
.blog_collection .readmoreblog .read_desc{
font-style: italic;
font-weight: 700;
font-size: 16.0667px;
line-height: 30px;
align-items: center;
text-align: center;
color: #3F4751;
margin-bottom: 25px;

}
.blog_collection .readmoreblog{
    text-align: center;
}
.blogdetail_page .exp_title{
font-style: italic;
font-weight: 700;
font-size: 26px;
line-height: 40px;
align-items: center;
color: #0B0C0D;
margin-bottom: 25px;
}
.blogdetail_page .exp_desc{

font-weight: 500;
font-size: 16px;
line-height: 34px;
align-items: center;
color: #3F4751;

}
    /* blogdetailpage end */

.blog_slide .owl-nav{
    display: unset !important;
}
.blog_slide .nav-btn.next-slide{
    position:absolute;
    right: -15px;
    top: 20%;
    background-color: #1A90DD;
    width: 30px;
    height: 30px;
    border-radius: 50px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
}
.blog_slide .nav-btn.prev-slide{
    position:absolute;
    left: -15px;
    top: 20%;
    background-color: #1A90DD;
    width: 30px;
    height: 30px;
    border-radius: 50px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
}

.blog_slide .nav-btn.next-slide:hover,
.blog_slide .nav-btn.prev-slide:hover{
    background-color: #fff;
    color: #1A90DD;
    border: 1px solid #1A90DD;
}

.footer_last_sec .top_height {
    padding-top: 27%;
}

.blogdetailcard .owl-carousel {
    margin-top: 60px;
}
.blogdetailcard .owl-nav {
    position: absolute;
    top: -20%;
    left: 48%;
}

.blogdetail_page .custom_container_blog{
max-width: 75%;
}

.blogdetail_page .blogdetail_banner{
    object-fit: cover;
    width: 100%;
    min-height: 600px;
    max-height: 600px;
    margin-bottom: 25px;
    
}
.blog_top_card:hover{
    box-shadow: 0 2px 8px 0 rgb(0 0 0 / 15%);
}
.blogcat_box:hover{
    box-shadow: 0 2px 8px 0 rgb(0 0 0 / 15%);
}


.form-control:focus{
    box-shadow: none;
}
.headerpage .navbar-expand-lg .navbar-nav .nav-link{
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 500 !important;
}
.headerpage .navbar-expand-lg .navbar-nav .nav-link.active{
    color: #1A90DD !important;
}
.headerpage  .navbar-expand-lg .navbar-nav{
    gap: 12px;
}
.ppc_page .sol_sec .extra_card.red_clr {
    top: 47%;
}
.ppc_page    .sol_sec .extra_card.green_clr {
    top: 47%;
}
.seo_page .sol_sec .extra_card.orange_clr .leftsec {
    font-size: 24px;
}
.seo_page  .sol_sec .extra_card.red_clr .leftsec {
    font-size: 20px;
}
.seo_page  .sol_sec .extra_card.blue_clr .leftsec {

    font-size: 24px;
}
.seo_page  .sol_sec .extra_card.green_clr .leftsec {
    font-size: 24px;
}
.seo_page .banner_sec .hash_img {
    right: -165px;
}
.seo_page .banner_sec .banner_line_img {
    right: -80px;
}
.seo_page .banner_sec .banner_line_img  .banner_lines{
    width: 90px;
}
.seo_page .sol_sec .extra_card.orange_clr {
    left: 35%;
    top: -10%;
}
.seo_page .sol_sec .extra_card.green_clr {
    left: 12%;
    top: 35%;
}
.seo_page .sol_sec .extra_card.blue_clr {
    right: 28%;
    bottom: -12%;
}
.seo_page .sol_sec .extra_card.red_clr {
    top: 34%;
}
.ppc_page .sol_sec .extra_card.blue_clr {
    bottom: -12%;
}
.ppc_page .sol_sec .extra_card.orange_clr {
top: 5%;
left: 34%;
}
.headerpage.navbar-nav a:active{
    color: #0096f7;
    font-weight: 600 !important;
}
.seo_page .banner_line_img,.seo_page .hash_img,
.seo_page .banner_sec .rightsec .twitter_img,
.seo_page .banner_sec .rightsec .fb_img,
.seo_page .banner_sec .rightsec .insta_img{
    display: none;
}
.ppc_page .banner_line_img,.ppc_page .hash_img,
.ppc_page .banner_sec .rightsec .twitter_img,
.ppc_page .banner_sec .rightsec .fb_img,
.ppc_page .banner_sec .rightsec .insta_img{
    display: none;
}
.blog_category_list .owl-dots{
    display: none;
}
.blogcategory_page .trend_sec{
    padding-top: 80px;
} 

 @media only screen and (max-width:575px){
    .blogpage .top_sec {
        margin-top: 50px;
    }
    .blog_slide .nav-btn.prev-slide,.blog_slide .nav-btn.next-slide{
        position: unset !important;
    }
    .blog_slide .owl-nav{
        display: flex !important;
        justify-content: center;
    }
    .blog_slide .owl-dots{
        display: none;
    }
    .footersec .contactus_sec {

        height: 100%;
    }
    .footersec .contactus_sec .sec_row {
        width: 100%;
    }
    .blogpage .trend_sec .rightsec{
        margin-top: 20px;
    }
    .trend_sec .trend_img {
        min-height: 270px;
        max-height: 270px;
        object-fit: cover;
    }
    .trend_sec .owl-theme .owl-dots{
        position: unset;
    }
    .trend_sec {
        padding-bottom: 30px;
    }
    .footer_last_sec .top_height {
        padding-top: 40px;
    }
    .seo_page .banner_sec .hash_img {
        right: -145px;
    }
    .seo_page  .banner_sec .banner_line_img {

        right: -90px;
    }
 }
 @media only screen and (min-width:576px) and (max-width:767px){
    .blogpage .top_sec {
        margin-top: 50px;
    }

    .trend_sec .owl-theme .owl-dots{
        position: unset;
    }
    .blog_slide .nav-btn.prev-slide,.blog_slide .nav-btn.next-slide{
        position: unset !important;
    }
    .blog_slide .owl-nav{
        display: flex !important;
        justify-content: center;
    }
    .blog_slide .owl-dots{
        display: none;
    }
    .footersec .contactus_sec {

        height: 100%;
    }
    .footersec .contactus_sec .sec_row {
        width: 100%;
    }
    .footer_last_sec .top_height {
        padding-top: 40px;
    }
    .ppc_page .banner_sec .banner_line_img {
        right: 12%;
    }
   .seo_page .banner_sec .rightsec {
        margin-top: 30px;
    }
    .seo_page .banner_sec .hash_img {
        right: -165px;
    }
    .seo_page .banner_sec .banner_line_img {
        right: -80px;
    }
    .seo_page .banner_sec .banner_line_img  .banner_lines{
        width: 90px;
    }


 }

 @media only screen and (min-width:768px) and (max-width:991px){

    .blogpage .top_sec {
        margin-top: 50px;
    }

    .trend_sec .owl-theme .owl-dots{
        position: unset;
    }
    .blog_slide .nav-btn.prev-slide,.blog_slide .nav-btn.next-slide{
        position: unset !important;
    }
    .blog_slide .owl-nav{
        display: flex !important;
        justify-content: center;
    }
    .blog_slide .owl-dots{
        display: none;
    }
    .footersec .contactus_sec {

        height: 100%;
    }
    .footersec .contactus_sec .sec_row {
        width: 100%;
    }
    .footer_last_sec .top_height {
        padding-top: 40px;
    }
    .seo_page .banner_sec .rightsec {
        margin-top: 30px;
    }
    .seo_page .banner_sec .hash_img {
        right: -165px;
    }
    .seo_page .banner_sec .banner_line_img {
        right: -80px;
    }
    .seo_page .banner_sec .banner_line_img  .banner_lines{
        width: 90px;
    }


 }

 @media only screen and (min-width:992px) and (max-width:1199px){
    .trend_sec .owl-theme .owl-dots {
        left: 37%;
    }
    .footersec .contactus_sec {

        height: 100%;
    }
    .footersec .contactus_sec .sec_row {
        width: 100%;
    }
    .footer_last_sec .top_height {
        padding-top: 40px;
    }
   .ppc_page .banner_sec .banner_line_img .banner_lines {
      width: 90px;
    }
   
    .seo_page .banner_sec .hash_img {
        right: -165px;
    }
    .seo_page .banner_sec .banner_line_img {
        right: -80px;
    }
    .seo_page .banner_sec .banner_line_img  .banner_lines{
        width: 90px;
    }
 }
 @media only screen and (min-width:1200px) and (max-width:1350px){
    .footer_last_sec .top_height {
        padding-top: 30%;
    }
    .ppc_page .sol_sec .extra_card.red_clr {
        top: 47%;
    }
    .ppc_page  .sol_sec .extra_card.green_clr {
        top: 48%;
    }
    .ppc_page .sol_sec .extra_card.orange_clr {
        left: 32%;
        top: 5%;
    }
    .ppc_page .sol_sec .extra_card.blue_clr {

        bottom: -14%;
    }
 }
 @media only screen and (min-width:1351px) and (max-width:1400px){
    .footer_last_sec .top_height {
        padding-top: 30%;
    }
 }

 @media only screen and (max-width: 992px) {
    .blogdetail_page .blogdetail_banner {
        min-height: auto;
        max-height: max-content;
    }
 }

 @media only screen  and (max-width:991px){
 .headerpage .navbar-collapse {
    justify-content: end;
    position: absolute;
    background: #fff;
    width: 100%;
    top: 70px;
    padding: 10px;
    z-index: 9;
    left: 0px;
    box-shadow: 0 2px 8px 0 rgb(0 0 0 / 10%);
    }
 }

 .ppc_page .sol_sec .extra_card.blue_clr .leftsec {
    background-color: #e9f7ff;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    width: 90px;
    font-size: 24px;
}

.blog_card .blogimgs .blog_card_img, .blogdetail_page .blogimgs .blogdetail_banner {
    object-fit: contain;
}

.blog_card .blogimgs, .blogdetail_page .blogimgs {
    background-color: #e5f1ff;
}

